import React from "react";
import { TrashIcon } from "@heroicons/react/solid";
import { Chapter, SubChapter } from "../types";

interface ChapterListProps {
  chapters: Chapter[];
  selectedChapter: Chapter | null;
  selectedSubChapter: SubChapter | null;
  onChapterClick: (chapter: Chapter) => void;
  onSubChapterClick: (chapter: Chapter, subChapter: SubChapter) => void;
  onDeleteChapter: (chapter: Chapter, e: React.MouseEvent) => void;
  onDeleteSubChapter: (
    chapter: Chapter,
    subChapter: SubChapter,
    subChapterIndex: number,
    e: React.MouseEvent
  ) => void;
  onAddNewChapter: () => void;
}

export const ChapterList: React.FC<ChapterListProps> = ({
  chapters,
  selectedChapter,
  selectedSubChapter,
  onChapterClick,
  onSubChapterClick,
  onDeleteChapter,
  onDeleteSubChapter,
  onAddNewChapter,
}) => {
  return (
    <div className="w-1/4 bg-white rounded-lg shadow p-4 overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Kapitel</h2>
        <button
          onClick={onAddNewChapter}
          className="px-3 py-1 text-sm rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
        >
          + Neues Kapitel
        </button>
      </div>
      <ul className="space-y-2">
        {chapters.map((chapter) => (
          <li key={chapter.id}>
            <div
              className={`p-2 rounded cursor-pointer transition-colors ${
                selectedChapter?.id === chapter.id && !selectedSubChapter
                  ? "bg-cyan-600 text-white font-semibold"
                  : "hover:bg-gray-100"
              }`}
            >
              <div className="flex justify-between items-center">
                <span onClick={() => onChapterClick(chapter)}>
                  {`${chapter.id}. ${chapter.title}`}
                </span>
                <button
                  onClick={(e) => onDeleteChapter(chapter, e)}
                  className="ml-2 px-2 py-1 text-xs rounded focus:text-white-600 focus:outline-none hover:text-red-500"
                >
                  <TrashIcon className="flex-shrink-0 h-4" />
                </button>
              </div>
            </div>
            {/* Subchapters */}
            <ul className="ml-4 mt-1 space-y-1">
              {chapter.subChapters.map((subChapter, index) => (
                <li
                  key={subChapter.id}
                  className={`p-2 rounded cursor-pointer transition-colors ${
                    selectedSubChapter?.id === subChapter.id
                      ? "bg-cyan-600 text-white font-semibold"
                      : "hover:bg-gray-100"
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <span
                      onClick={() => onSubChapterClick(chapter, subChapter)}
                    >
                      {`${subChapter.id}. ${subChapter.title}`}
                    </span>
                    <button
                      onClick={(e) =>
                        onDeleteSubChapter(chapter, subChapter, index, e)
                      }
                      className="ml-2 px-2 py-1 text-xs rounded hover:bg-red-100 focus:outline-none focus:text-white-600 hover:text-red-500"
                    >
                      <TrashIcon className="flex-shrink-0 h-4" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};
