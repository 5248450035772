import React from "react";
import { ManualEditor } from "components/inputs/ManualEditor";
import { Chapter, SubChapter } from "../types";
import { Descendant } from "slate";

interface ChapterEditorProps {
  selectedChapter: Chapter | null;
  selectedSubChapter: SubChapter | null;
  getChapterContent: (chapter: Chapter) => Descendant[];
  onSave: (updatedContent: Descendant[]) => void;
}

export const ChapterEditor: React.FC<ChapterEditorProps> = ({
  selectedChapter,
  selectedSubChapter,
  getChapterContent,
  onSave,
}) => {
  return (
    <div className="w-3/4 flex flex-col">
      <ManualEditor
        label={
          selectedSubChapter
            ? `Editing: ${selectedChapter?.title} - ${selectedSubChapter.title}`
            : selectedChapter
            ? `Editing: ${selectedChapter.title}`
            : "Select a chapter to edit"
        }
        onBlur={onSave}
        defaultValue={selectedChapter ? getChapterContent(selectedChapter) : []}
        key={`${selectedChapter?.id}-${selectedSubChapter?.id}`} // Force re-render when selection changes
      />
    </div>
  );
};
